import React, { useState } from "react";
import Login from "./Login";
import Principal from "./Principal";
import Register from "./Register";
import './auth.css'

function Auth() {
  const [lg, setLg] = useState(true);
  return (
    <div
      style={{ padding: 0, margin: 0, flexDirection:"row" }}
      className="form-group row col-lg-12  "
    >
      <div
        style={{ height: window.innerHeight }}
        className=" form-group col-lg-7 ocultar-div"
        
      >
        <Principal />
      </div>
      <div
        style={{ padding: 0, margin: 0, alignItems: "center", display: "flex", justifyContent:"center", alignContent:"center" }}
        className="form-group col-lg-5"
      >
        {lg ? <Login setLg={setLg} /> : <Register setLg={setLg} />}
      </div>
    </div>
  );
}

export default Auth;
