import { Switch, Route, Redirect } from 'react-router-dom';
import React, { useContext, lazy } from 'react';
import VerifyToken from '../utils/verifyToken';
import ContextSidebar from '../context/ContextSidebar';

import { useFirestore, useStorage } from 'reactfire';

import 'firebase/firestore';
import 'firebase/storage';

import './router.css';
import routes from '../routes';
const SideBar = lazy(() => import('../Components/Navigation/SideBar'));
const Navbar = lazy(() => import('../Components/Navigation/Navbar'));
const Footer = lazy(() => import('../Components/Navigation/Footer'));

function PrivateRouter() {
	const { position } = useContext<any>(ContextSidebar);
	VerifyToken();
	const db = useFirestore();
	const storage = useStorage();

	return (
		<>
			<Navbar />
			<SideBar />
			<div
				className={position ? 'PrivateRouter' : 'PrivateRouter active'}
				style={{ float: 'right', minHeight: window.screen.height - 350 }}
			>
				<Switch>
					{routes.map((route, idx) => {
						return (
							route.component && (
								<Route
									key={idx}
									path={route.path}
									exact={route.exact}
									render={(props: any) => (
										<route.component
											{...props}
											firestore={db}
											storage={storage}
										/>
									)}
								/>
							)
						);
					})}
					<Redirect from="/**" to="/" />
				</Switch>
			</div>
			<Footer />
		</>
	);
}

export default PrivateRouter;
