import React, { lazy } from 'react';

const CategoriesList = lazy(
	() => import('./Components/Categories/CategoriesList')
);
const CategoriesForm = lazy(
	() => import('./Components/Categories/CategoriesForm')
);
const QuestionBank = lazy(() => import('./Components/Questions/QuestionBank'));
const QuestionForm = lazy(() => import('./Components/Questions/QuestionForm'));
const CategoryEdit = lazy(() => import('./Components/Categories/CategoryEdit'));
const QuestionViewEdit = lazy(
	() => import('./Components/Questions/QuestionViewEdit')
);
const Ubications = lazy(() => import('./Components/Questions/Ubications'));
const EstacionesForm = lazy(
	() => import('./Components/Estaciones/Estacionesform')
);
const EstacionesList = lazy(
	() => import('./Components/Estaciones/EstacionesList')
);
const EstacionesViewEdit = lazy(
	() => import('./Components/Estaciones/EstacionesViewEdit')
);
const CuponesList = lazy(() => import('./Components/Cupones/List'));
const CuponesNew = lazy(() => import('./Components/Cupones/New'));

const Users = lazy(() => import('./Components/Users'));

const routes = [
	{ path: '/', exact: true, name: 'CategoriesList', component: CategoriesList },
	{
		path: '/categoriesform',
		name: 'CategoriesForm',
		component: CategoriesForm,
	},
	{ path: '/questionbank/:id', name: 'QuestionBank', component: QuestionBank },
	{ path: '/questionform/:id', name: 'QuestionForm', component: QuestionForm },
	{ path: '/ubications/:id', name: 'Ubications', component: Ubications },
	{
		path: '/questionview/:id',
		name: 'QuestionViewEdit',
		component: QuestionViewEdit,
	},
	{ path: '/categoryedit/:id', name: 'CategoryEdit', component: CategoryEdit },
	{
		path: '/stationsform/:id',
		name: 'EstacionesForm',
		component: EstacionesForm,
	},
	{
		path: '/stationslist/:id/cupones/nuevo',
		name: 'CuponesNew',
		component: CuponesNew,
	},
	{
		path: '/stationslist/:id/cupones',
		name: 'CuponesList',
		component: CuponesList,
	},
	{
		path: '/stationslist/:id',
		name: 'EstacionesList',
		component: EstacionesList,
	},
	{
		path: '/stationsview/:id',
		name: 'EstacionesViewEdit',
		component: EstacionesViewEdit,
	},
	{
		path: '/usuarios',
		name: 'Usuarios',
		component: Users,
	},
];

export default routes;
