export default {
   apiKey: "AIzaSyD76nKVcPNLXQ0_8bRh_IdMsltEqCQYY-A",
  authDomain: "trivias-4ea51.firebaseapp.com",
  projectId: "trivias-4ea51",
  storageBucket: "trivias-4ea51.appspot.com",
  messagingSenderId: "741192002845",
  appId: "1:741192002845:web:397feb0e2bf187315cc5f4",
  measurementId: "G-3NFVD4H9JB"
  };

  
